import { useTranslation } from 'react-i18next';
import {
  Menu,
  Text,
  Divider,
  Anchor,
  Beta,
  UnstyledButton,
} from '@liveeo/component-library';
import { useAuth0User } from '../../hooks';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import classes from './AppMenubar.module.css';

export const Profile = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { auth0User } = useAuth0User();
  const [opened, setOpened] = useState(false);
  const helpCenterLink =
    'https://tradeaware.wikipage.io/c/4096086/tradeaware+help+center';
  const logoutUser = async () => {
    await logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  return auth0User ? (
    <Menu opened={opened} onChange={setOpened} offset={15} position="right-end">
      <Menu.Target>
        <UnstyledButton>
          <Beta.Icon icon="user" />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className={classes.dropdown}>
        <Menu.Item>
          <Link to="/admin" className={classes.link}>
            <Text fz="sm">Manage account</Text>
            <Text fz="xs">{auth0User.email}</Text>
          </Link>
        </Menu.Item>
        <Divider />
        <Menu.Item className={classes.menuItem}>
          <Anchor
            className={classes.link}
            href={helpCenterLink}
            target="_blank"
          >
            <Text fz="xs">{t('header.supportCenter')}</Text>
          </Anchor>
        </Menu.Item>
        <Divider />
        <Menu.Item className={classes.menuItem}>
          <Text fz="xs" onClick={logoutUser} className={classes.link}>
            {t('header.logout')}
          </Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  ) : null;
};
