import { FeatureFlags } from '../shared/types/feature-flags';

export const featureFlags: FeatureFlags = {
  COMMERCIAL_GRAPH: {
    dev: true,
    staging: true,
    prod: true,
  },
  COMMERCIAL_GRAPH_v2: {
    dev: false,
    staging: false,
    prod: false,
  },
  // TODO We should remove this feature flag completely when M2MClients feature is stable.
  // Leaving it in place for now as a fall back if an issue is reported in prod but
  // it should be removed soon unless explicitly asked to be kept - 22/07/24
  M2MClients: {
    dev: true,
    staging: true,
    prod: true,
  },
};
