import { Source, Layer, useMap } from 'react-map-gl';
import type { FillLayer, LineLayer } from 'react-map-gl';
import type { FeatureCollection } from 'geojson';
import { usePlots, useLayerState } from '../../hooks';
import { FillLayout } from 'mapbox-gl';
import { memo } from 'react';
import { useTableState } from '../../hooks/useTableState';

const plotLayerStyle: FillLayer = {
  id: 'plot-polygon',
  type: 'fill',
  source: 'plots',
  paint: {
    'fill-opacity': 0,
  },
};
const plotOutlineStyle: LineLayer = {
  id: 'plot-outline',
  type: 'line',
  source: 'plots',
  paint: {
    'line-color': '#FCFCFC',
    'line-width': 4,
  },
};

const PlotsLayer = () => {
  const { isLoading, plotsAsFeatureCollection } = usePlots();
  const { layerState } = useLayerState();
  const { tableState } = useTableState();
  const { deforestationMap: map } = useMap();
  const selectedPlotId = tableState.sel || '';
  const layerVisibility: FillLayout = {
    visibility: layerState.plots ? 'visible' : 'none',
  };

  return !isLoading && map?.getMap() ? (
    <Source
      id="plots"
      type="geojson"
      data={plotsAsFeatureCollection as FeatureCollection}
    >
      <Layer {...plotLayerStyle} layout={layerVisibility} />
      <Layer
        {...plotOutlineStyle}
        layout={layerVisibility}
        paint={{
          'line-color': '#EBFF00',
          'line-dasharray': [
            'case',
            ['==', ['get', 'plotId'], selectedPlotId],
            ['literal', [2, 1]],
            ['literal', [1]],
          ],
          'line-width': [
            'case',
            ['==', ['get', 'plotId'], selectedPlotId],
            3.5,
            2.5,
          ],
          'line-opacity': [
            'case',
            ['==', ['get', 'plotId'], selectedPlotId],
            1,
            0.8,
          ],
        }}
      />
    </Source>
  ) : null;
};

export default memo(PlotsLayer);
