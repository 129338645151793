import { useFlags } from '../../contexts/FlagsContext';
import { UserProfile as UserProfileWithAccessToken } from './UserProfileAccessToken';
import { UserProfile as UserProfileWithM2MClient } from './UserProfileM2MClient';

export const UserProfileWrapper = () => {
  const { M2MClients } = useFlags();

  // TODO We should remove this feature flag completely when M2MClients feature is stable.
  // Leaving it in place for now as a fall back if an issue is reported in prod but
  // it should be removed soon unless explicitly asked to be kept - 22/07/24
  if (M2MClients) return <UserProfileWithM2MClient />;
  return <UserProfileWithAccessToken />;
};
