import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import gjv from 'geojson-validation';
import uploadIcon from '../../../assets/upload-icon.svg';
import { Feature, FeatureCollection, Polygon, MultiPolygon } from '@turf/turf';
import { SelectCommodity } from './SelectCommodity';
import { UploadResults } from './UploadResults';
import { ParsedFile } from '../../shared/types/bulk-upload';
import { useFetchApi, useTracking } from '../../hooks';
import {
  Dropzone,
  FileWithPath,
  Group,
  Image,
  Stack,
  Text,
  Flex,
} from '@liveeo/component-library';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import classes from './BulkUploadFiles.module.css';

type Props = {
  plotsWithErrors: ParsedFile[];
  setPlotsWithErrors: (f: ParsedFile[] | any) => void;
  setIsButtonDisabled: (b: boolean) => void;
  isButtonDisabled: boolean;
  successfulUploads: ParsedFile[];
  setSuccessfulUploads: (f: ParsedFile[] | any) => void;
  showUploader: boolean;
  setShowUploader: (b: boolean) => void;
  context: string;
};

const checkIfLngLatIsCRS84 = (coords: any) => {
  const [lng, lat] = coords;
  return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
};

const checkSupportedGeometryType = (geometries: Feature[]) => {
  return geometries
    .map((feature: Feature, i: number) => {
      if (
        gjv.isPolygon(feature.geometry) ||
        gjv.isMultiPolygon(feature.geometry)
      )
        return [];
      return `at ${i}: Type must be a Polygon or MultiPolygon`;
    })
    .flat();
};

const validateGeoJson = (file: FileWithPath): Promise<ParsedFile> => {
  const reader = new FileReader();
  reader.readAsText(file as FileWithPath);

  return new Promise((resolve, reject) => {
    reader.onload = (event: any) => {
      try {
        const data = JSON.parse(event.target.result) as any;
        const geometries = data.features || [data]; // TODO: A bit hacky!

        const checkGeometryIsCRS84 = (coordinates: number[][]) => {
          if (coordinates?.length) {
            return coordinates.forEach((coords: number[]) => {
              if (!coords.every(checkIfLngLatIsCRS84)) {
                return resolve({
                  filename: file.name,
                  fileSize: file.size,
                  errors: ['file not in CRS84 projection'],
                  status: 'ERROR',
                });
              }
            });
          } else {
            return resolve({
              filename: file.name,
              fileSize: file.size,
              errors: ['file error, coordinates array is empty'],
              status: 'ERROR',
            });
          }
        };

        const hasGeometryErrors = checkSupportedGeometryType(geometries);
        if (hasGeometryErrors.length)
          return resolve({
            filename: file.name,
            fileSize: file.size,
            errors: hasGeometryErrors,
            status: 'ERROR',
          });

        // Validate that projections are CRS84 before continuing upload process -
        // files containing such errors will not be sent to the BE.
        // FE validation of large GeoJSON's will become an issue in the future and
        // we may need to move alot of this logic to the BE
        geometries.forEach(({ geometry }: Polygon | MultiPolygon | any) => {
          const { type, coordinates } = geometry;
          if (type === 'MultiPolygon') checkGeometryIsCRS84(coordinates[0]);
          else checkGeometryIsCRS84(coordinates);
        });

        const hasErrors = data.features
          ? gjv.isFeatureCollection(data, true)
          : gjv.isFeature(data, true);
        if (hasErrors.length)
          return resolve({
            filename: file.name,
            fileSize: file.size,
            errors: hasErrors,
            status: 'ERROR',
          });

        resolve({
          filename: file.name,
          fileSize: file.size,
          geometries: (data.features || [data]).reduce(
            (acc: FeatureCollection, feature: Feature, i: number) => ({
              ...acc,
              [`${file.name}-${i}`]: feature.geometry,
            }),
            {}
          ),
          status: 'STANDBY',
        });
      } catch (e) {
        reject({ e, file });
      }
    };
  });
};

export const BulkUploadFiles = ({
  plotsWithErrors,
  setPlotsWithErrors,
  setIsButtonDisabled,
  isButtonDisabled,
  successfulUploads,
  setSuccessfulUploads,
  showUploader,
  setShowUploader,
  context,
}: Props) => {
  const { t } = useTranslation();
  const fetchApi = useFetchApi();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [commodity, setCommodity] = useState<string>('');
  const [plotsToCreate, setPlotsToCreate] = useState<ParsedFile[]>([]);
  const queryClient = useQueryClient();
  const { trackEvent } = useTracking();

  const createValidatedPlots = (plots: ParsedFile[], commodity: string) => {
    if (!plots.length || !commodity) return;

    setIsButtonDisabled(true);
    const errorsFromAPI: ParsedFile[] = [];
    const successFromAPI: ParsedFile[] = [];
    let totalProcessedPlots = 0;

    /*
      TODO we should revisit this setup on the FE and BE in the future.
      This could become a Prmoise.all if the filename was being sent in the response
      but maybe its not the best solution as we need to keep track of files that were
      updated succesffuly and ones with errors from the BE.
    */
    plots.forEach((plot: ParsedFile) => {
      // New DTO structure when sending to the BE
      // status & fileSize have been removed as a result of Pentest findings
      const plotToSend = {
        filename: plot.filename,
        errors: plot.errors,
        geometries: plot.geometries,
      };

      return fetchApi('plots/batch', {
        method: 'POST',
        body: JSON.stringify({
          ...plotToSend,
          commodities: [commodity], // TODO: We should mimic multi commodity plots
        }),
      })
        .then(async (res: any) => {
          totalProcessedPlots++;
          // Duplicate plots are returned with status 20X - the error isa handled below
          const duplicatePlots = Object.keys(res.errors);
          const plotsCreated = Object.keys(res.processed);
          if (duplicatePlots.length) {
            duplicatePlots.forEach((filename: string) =>
              errorsFromAPI.push({
                ...plot,
                filename: filename,
                errors: [res.errors[filename].message],
                status: 'ERROR',
              })
            );
          }
          if (plotsCreated.length) {
            successFromAPI.push({ ...plot, status: 'DONE' });
          }
          if (res.status === 'SUCCESS') {
            await Promise.all(
              Object.values(res.processed).map((value: string | any) => {
                // Ex. /plots/f627f96e-0c13-43e2-880f-a88e0a22759b
                const plotId = value.split('/')[2];
                return fetchApi(`plots/${plotId}/analyses`, {
                  method: 'POST',
                  body: JSON.stringify({
                    name: value,
                    type: 'EUFOROBS',
                    metadata: {},
                  }),
                });
              })
            );
          }
          trackEvent(context, {
            step: 'import-plots',
            action: 'upload-plots-success',
          });
        })
        .catch((err) => {
          totalProcessedPlots++;
          errorsFromAPI.push({
            ...plot,
            errors: [err.message],
            status: 'ERROR',
          });
          trackEvent(context, {
            step: 'import-plots',
            action: 'upload-plots-error',
            error: err.message,
          });
          Sentry.captureException(err.message);
        })
        .finally(() => {
          if (totalProcessedPlots === plots.length) {
            // We need this to track prev uploads to show all uploaded files after a user has retried a corrected file
            setPlotsToCreate([]);
            setSuccessfulUploads((prevUploads: ParsedFile[]) => [
              ...prevUploads,
              ...successFromAPI,
            ]);
            setPlotsWithErrors((prevUploads: ParsedFile[]) => [
              ...prevUploads,
              ...errorsFromAPI,
            ]);
            setIsButtonDisabled(false); // block user actions while files are being uploaded
            queryClient.invalidateQueries({ queryKey: ['plots'] });
          }
        });
    });
  };

  const parseUploadedFiles = (filesToParse: FileWithPath[]) => {
    const validatedPlots: ParsedFile[] = [];

    // Remove files which have been successfuly uploaded in previous attempt
    const prevUploadedFiles = successfulUploads.map(
      (f: ParsedFile) => f.filename
    );
    const newFilesToUpload = filesToParse.filter(
      (f: FileWithPath) => !prevUploadedFiles.includes(f.name)
    );

    Promise.all(newFilesToUpload.map(validateGeoJson))
      .then((parsedFiles) => {
        parsedFiles.forEach((parsedFile) => {
          if (parsedFile.errors) {
            setPlotsWithErrors((plotsWithErrors: ParsedFile[]) => [
              ...plotsWithErrors,
              parsedFile as ParsedFile,
            ]);
          } else {
            setPlotsToCreate((plotsToCreate: ParsedFile[]) => [
              ...plotsToCreate,
              parsedFile as ParsedFile,
            ]);
            validatedPlots.push(parsedFile);
          }
        });
      })
      .finally(() => {
        // When re-uploading failed files we bypass commodity as it has already been selected in the prev stage -
        // this will only run when users retry an upload because the commodity select is trigger after this function has ran
        if (commodity) createValidatedPlots(validatedPlots, commodity);
        setShowUploader(false);
      });
  };

  return (
    <>
      {isOpen && !commodity && plotsToCreate.length ? (
        <SelectCommodity
          setCommodity={(commodity) => {
            if (commodity) {
              setIsOpen(false);
              setCommodity(commodity);
              createValidatedPlots(plotsToCreate, commodity);
              setShowUploader(false);
            }
            trackEvent(context, {
              step: 'select-commodity',
              action: commodity,
            });
          }}
          resetUploader={() => {
            setIsOpen(false);
            setShowUploader(true);
            setPlotsWithErrors([]);
            setPlotsToCreate([]);
          }}
        />
      ) : null}
      {!showUploader ? (
        <Stack mih={220} gap="xs" className={classes.container}>
          <UploadResults
            plotUploadStatus={[
              // Ensuring successfulUploads is unique
              // This is a temp fix for a race condition when uploading small geojson files.
              ...successfulUploads.filter((obj, index, arr) => {
                return (
                  arr.findIndex((file) => {
                    return file.filename === obj.filename;
                  }) === index
                );
              }),
              ...plotsToCreate,
            ]}
            plotsWithErrors={plotsWithErrors}
            isButtonDisabled={isButtonDisabled}
            resetUploader={() => {
              setShowUploader(true);
              setPlotsWithErrors([]);
            }}
          />
        </Stack>
      ) : (
        <Dropzone
          className={classes.root}
          accept={{
            'application/geo+json': ['.geojson'],
          }}
          maxSize={50 * 1024 ** 2}
          onDrop={(files: FileWithPath[]) => {
            parseUploadedFiles(files);
            setIsOpen(true);
          }}
          onReject={(rejects) =>
            rejects.forEach((reject) =>
              setPlotsWithErrors((prevErrors: ParsedFile[]) => [
                ...prevErrors,
                {
                  filename: reject.file?.name || 'unknown',
                  fileSize: reject.file?.size || 0,
                  errors: reject.errors.map(({ message }) => message),
                  status: 'ERROR',
                },
              ])
            )
          }
          onFileDialogCancel={() =>
            trackEvent(context, {
              step: 'import-plots',
              action: 'upload-plots-cancel',
            })
          }
        >
          <Group mih={220} align="center" m={0}>
            <Flex justify="center" align="center" direction="column">
              <Image h={50} w="auto" fit="contain" src={uploadIcon} />
              <Stack gap="xs" align="center">
                <Text size="xl" inline ta="center">
                  {t('profile.onboarding.steps.import.upload.title')}
                </Text>
                <Text size="sm" inline mt={7}>
                  {t('profile.onboarding.steps.import.upload.text')}
                </Text>
              </Stack>
            </Flex>
          </Group>
        </Dropzone>
      )}
    </>
  );
};
