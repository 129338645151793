import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFlags } from '../../contexts/FlagsContext';
import { useUser } from '../../hooks';
import {
  Flex,
  Button,
  Grid,
  Stack,
  Title,
  Text,
  Image,
} from '@liveeo/component-library';
import {
  deforestationDisturbance,
  deforestationRiskMapping,
} from '../../helper';
import { PlotRiskText } from './PlotRiskText';
import SUPPLIERS_ICON from '../../../assets/suppliers-icon.svg';
import classes from './CollapsedRow.module.css';
import { useResetParams } from '../../hooks/useRestParams';

type Props = {
  riskScore: number | undefined;
  supplierName: string;
  supplierId: string | null;
  plotId: string;
  isSupplierView: boolean;
};

export const CollapsedRow = ({
  riskScore,
  supplierName,
  supplierId,
  plotId,
  isSupplierView,
}: Props) => {
  const { t } = useTranslation();
  const { COMMERCIAL_GRAPH } = useFlags();
  const { data: user } = useUser();
  const { resetParams } = useResetParams();

  return (
    <>
      {supplierId && plotId && COMMERCIAL_GRAPH ? (
        <Stack className={classes.root}>
          <Text size="sm" fs="italic">
            {t('analysis.title')}
          </Text>
          <Grid grow>
            <Grid.Col span={5}>
              <Stack>
                <Title order={5}>{t('analysis.layers.global')}</Title>
                <Title order={5}>{t('analysis.layers.precision')}</Title>
              </Stack>
            </Grid.Col>
            <Grid.Col span={4}>
              <Stack>
                <Text>
                  {t(`analysis.disturbance`, {
                    percentage: deforestationDisturbance(riskScore),
                  })}
                </Text>
                <Text>{t('analysis.notAvailable')}</Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={2}>
              <Stack>
                <Text
                  className={classes.text}
                  data-hidden={typeof riskScore === 'undefined'}
                >
                  {t(`common.risk.${deforestationRiskMapping(riskScore)}`)}
                </Text>
                <Button
                  size="compact-sm"
                  to="https://live-eo.com/tradeaware-contact"
                  component={Link}
                  target="_blank"
                >
                  {t<string>('common.contact')}
                </Button>
              </Stack>
            </Grid.Col>
          </Grid>
          {!isSupplierView && supplierId !== user?.businessId && (
            <Button
              leftSection={<Image src={SUPPLIERS_ICON} w={15} />}
              className={classes.button}
              component={Link}
              to={`../suppliers/${supplierId}?${resetParams()}&sel=${plotId}`}
            >
              {supplierName}
            </Button>
          )}
        </Stack>
      ) : (
        <div>
          <div className={classes['subrow-collapsed']}>
            <PlotRiskText risk={deforestationRiskMapping(riskScore)} />
          </div>
          <Flex justify="flex-end">
            <Button
              size="sm"
              mx="sm"
              mt="sm"
              to="https://live-eo.com/tradeaware-contact"
              component={Link}
              target="_blank"
            >
              {t<string>('common.upgrade')}
            </Button>
          </Flex>
        </div>
      )}
    </>
  );
};
