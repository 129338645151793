import { useEffect } from 'react';
import MapGL, {
  GeolocateControl,
  LngLatLike,
  NavigationControl,
  useMap,
} from 'react-map-gl';
import { GeoLocationSearch } from './GeoLocationSearch';
import { DrawPolygon } from './DrawPolygon';
import { useMapViewState, usePlotState, usePlots } from '../../hooks';
import { Center, Loader } from '@liveeo/component-library';
import PlotsLayer from './PlotsLayer';
import { useTranslation } from 'react-i18next';
import { GlobalForestLayer } from './GlobalForestLayer';
import { getBbox } from '../../helper';
import * as Sentry from '@sentry/react';
import { useTableState } from '../../hooks/useTableState';
import { useToggleAside } from '../../hooks/useToggleAside';
import { Legend } from './Legend';

export const InteractiveMap = ({ draw, onDrawPlot, onDeletePlot }: any) => {
  const { t } = useTranslation();
  const state = usePlotState();
  const { viewState, setViewState } = useMapViewState();
  const { deforestationMap: map } = useMap();
  const { findPlotById } = usePlots();
  const { isAsideOpen } = useToggleAside();
  const { tableState, setTableState } = useTableState();
  const isFetchingMapLocation = Object.values(viewState).every(
    (v) => v === undefined
  );

  useEffect(() => {
    map?.resize();
  }, [map, isAsideOpen]);

  return isFetchingMapLocation ? (
    <Center>
      <Loader />
    </Center>
  ) : (
    <MapGL
      reuseMaps
      id="deforestationMap"
      logoPosition="top-left"
      style={{ width: '100%', height: '100vh' }}
      initialViewState={viewState}
      cursor={state?.cursor}
      mapStyle="mapbox://styles/app-team/cldyjkcoo006w01kght7pw3ey"
      mapboxAccessToken={import.meta.env['NX_EUDR_MAPBOX_ACCESS_TOKEN']}
      attributionControl={false}
      onMove={({ viewState: { longitude, latitude, zoom } }) =>
        setViewState({ longitude, latitude, zoom })
      }
      onClick={(e) => {
        if (state?.enableClick) {
          const pointClicked = map?.queryRenderedFeatures([
            e.point.x,
            e.point.y,
          ]);
          const plotId = pointClicked && pointClicked[0]?.properties?.plotId;
          const plot = findPlotById(plotId);

          if (plotId !== tableState.sel) {
            setTableState({
              sel: undefined,
              ex: undefined,
            });
          }
          if (plot) {
            const bbox = getBbox(plot.geometry);
            if (bbox.length) {
              map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
                duration: 0,
              });
            }

            setTableState({
              sel: plotId,
              ex: undefined,
            });
          }
        }
      }}
      onError={(e) => Sentry.captureException(e.error)}
    >
      <GeoLocationSearch position="top-right" />
      <GeolocateControl position="bottom-right" />
      <NavigationControl position="bottom-right" showCompass={true} />
      <PlotsLayer />
      <DrawPolygon
        draw={draw}
        onDrawPlot={onDrawPlot}
        onDeletePlot={onDeletePlot}
      />
      <GlobalForestLayer />
      <Legend />
    </MapGL>
  );
};
