import { PropsWithChildren } from 'react';
import classes from './Body.module.css';

type Props = PropsWithChildren<{
  onClick?: () => void;
  isSelected?: boolean;
}>;

export const Body = ({ children, ...props }: PropsWithChildren) => (
  <tbody {...props}>{children}</tbody>
);

export const Row = ({ children, onClick, isSelected = false }: Props) => {
  return (
    <tr onClick={onClick} className={classes.row} data-selected={isSelected}>
      {children}
    </tr>
  );
};

export const Cell = ({ children }: PropsWithChildren) => (
  <td className={classes['cell']}>
    <div className={classes['cellContent']}>{children}</div>
  </td>
);
