import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Title,
  Text,
  Stack,
  Divider,
  LoadingSpinner,
  Textarea,
  Group,
  Button,
  Image,
} from '@liveeo/component-library';
import { useToggle } from '@liveeo/helpers';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import { useUser, useAuth0User, useToken } from '../../hooks';
import classes from './Admin.module.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const UserProfile = () => {
  const { data: user } = useUser();
  const { data: token, isLoading } = useToken();
  const { auth0User } = useAuth0User();
  const [copied, setCopied] = useToggle();
  const { t } = useTranslation();

  let fullName = '';
  let email = '';
  if (user && auth0User) {
    fullName = `${user.firstName} ${user.lastName}`;
    email = auth0User.email;
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : token ? (
    <GenericErrorBoundary title={`${t('error.profile')}`}>
      <Flex<PropsWithChildren<any>>
        direction="column"
        gap="md"
        c="white"
        p="md"
        className={classes.profileContainer}
      >
        <Title order={1}>LiveEO</Title>
        <Text>About</Text>
        <Stack>
          <Flex<PropsWithChildren<any>> align="center" gap="md">
            <Image src="/profile.svg" />
            <Text>{fullName}</Text>
          </Flex>
          <Flex<PropsWithChildren<any>> align="center" gap="md">
            <Image src="/mail.svg" height={15} />
            <Text>{email}</Text>
          </Flex>
        </Stack>
        <Divider />

        <Group justify="space-between">
          <Text>API Key</Text>
          {token && (
            <CopyToClipboard
              text={token}
              onCopy={() => {
                setCopied();
              }}
            >
              {!copied ? (
                <Button
                  variant="outline"
                  size="compact-xs"
                  className={classes.button}
                >
                  Copy
                </Button>
              ) : (
                <Text size="sm">Copied to clipboard!</Text>
              )}
            </CopyToClipboard>
          )}
        </Group>
        <Textarea
          classNames={{ input: classes.input }}
          readOnly
          value={token}
          autosize
        />
      </Flex>
    </GenericErrorBoundary>
  ) : null;
};
