import { useMemo, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import { readableId } from '@liveeo/helpers';
import {
  defaultMapViewState,
  getBbox,
  toDateOnly,
  deforestationRiskMapping,
} from '../../helper/';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  functionalUpdate,
  OnChangeFn,
} from '@tanstack/react-table';
import {
  Icon,
  Text,
  UnstyledButton,
  StyledTooltip,
} from '@liveeo/component-library';
import { Table } from '../../shared/components/Table';
import { squareMetersToHectares } from '@liveeo/helpers';
import { isEqual } from 'lodash';
import { LngLatLike, useMap } from 'react-map-gl';
import { Plot, PlotTable } from '../../shared/types';
import { PlotsActionsMenu } from './PlotsActionsMenu';
import { StatusIndicator } from '../../shared/components/StatusIndicator';
import { useMapViewState, useUser } from '../../hooks';
import { useTableState } from '../../hooks/useTableState';
import { CollapsedRow } from './CollapsedRow';
import { useFlags } from '../../contexts/FlagsContext';

type Props = {
  plots: Plot[] | undefined;
  height: number;
  isSupplierView?: boolean;
};

const columnHelper = createColumnHelper<PlotTable>();

export const PlotsTable = ({
  plots,
  height,
  isSupplierView = false,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { deforestationMap: map } = useMap();
  const { plotId } = useParams();
  const { data: user } = useUser();
  const { viewState } = useMapViewState();
  const { tableState, setTableState, sorting, expanded } = useTableState();
  const selectedPlotId = tableState.sel;
  const pageIndex = tableState.pg as any;
  const { COMMERCIAL_GRAPH } = useFlags();

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: 'expander',
        cell: ({ row }) => (
          <UnstyledButton
            onClick={() => {
              setTableState({
                ex: row.getIsExpanded() ? undefined : row.index,
              });
            }}
          >
            <Icon
              icon={row.getIsExpanded() ? 'chevron-up' : 'chevron-down'}
              size="sm"
            />
          </UnstyledButton>
        ),
      }),
      columnHelper.accessor('name', {
        header: t('plots.table.name') ?? '',
        cell: (info) => (
          <StyledTooltip label={info.getValue() || ''} dark>
            <Text size="sm" c="colors.black400">
              {readableId(info.getValue()) || ''}
            </Text>
          </StyledTooltip>
        ),
      }),
      columnHelper.accessor('surfaceArea', {
        header: `${t('plots.table.surfaceArea')} (ha)`,
        cell: (info) => (
          <Text size="sm">
            {squareMetersToHectares(info.getValue(), 2) || ''}
          </Text>
        ),
      }),
      columnHelper.accessor('updatedAt', {
        header: t('plots.table.updatedAt') ?? '',
        cell: (info) => (
          <Text size="sm">
            {toDateOnly(info.getValue(), i18n.languages) || ''}
          </Text>
        ),
      }),
      columnHelper.accessor(
        (row) => row?.mostRecentAnalysis?.riskOfDeforestation,
        {
          header: t('plots.table.status') ?? '',
          cell: (info) => (
            <StatusIndicator text={deforestationRiskMapping(info.getValue())} />
          ),
        }
      ),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          // TODO - this duplication is annoying but necessary until the commercial graph invitation flow is complete
          return COMMERCIAL_GRAPH ? (
            !isSupplierView &&
              row.original.suppliers?.length &&
              user?.businessId === row.original?.suppliers[0].id && (
                <PlotsActionsMenu
                  item={row.original}
                  selectedPlotId={selectedPlotId}
                  disabled={!!plotId}
                />
              )
          ) : (
            <PlotsActionsMenu
              item={row.original}
              selectedPlotId={selectedPlotId}
              disabled={!!plotId}
            />
          );
        },
      }),
    ];
  }, [t, i18n.languages, selectedPlotId, plotId, user]);

  const table = useReactTable({
    data: plots || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      sorting,
      expanded,
      pagination: {
        pageIndex,
        pageSize: 20,
      },
    },
    getRowCanExpand: () => true,
    onSortingChange: (updaterFunction: OnChangeFn<SortingState> | any) => {
      const [newValue] = updaterFunction(sorting);
      if (newValue) {
        // reset table state when sorting headers used
        setTableState({
          pg: 0,
          sel: undefined,
          ex: undefined,
          sort: newValue.id,
          desc: newValue.desc,
        });
      }
    },
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: (updaterFunction) => {
      const newValue = functionalUpdate(updaterFunction, {
        pageIndex,
        pageSize: 20,
      });
      setTableState({
        pg: newValue.pageIndex,
        // reset selected plot and extended state when using table pagination
        sel: undefined,
        ex: undefined,
      });
    },
    enableSortingRemoval: false,
    autoResetPageIndex: false,
  });

  useEffect(() => {
    const sortedPlotIds = table
      .getSortedRowModel()
      .rows.map((r) => r.original.id);
    const plotIndex = sortedPlotIds?.findIndex((id) => id === selectedPlotId);
    if (plotIndex >= 0) {
      setTableState({ pg: Math.floor(plotIndex / 20) }); // 20 is the items per page
    }
  }, [selectedPlotId, setTableState, table]);

  return (
    <Table height={height}>
      <Table.Header>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.HeaderRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Table.HeaderCell
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
              >
                {/* span to avoid console warning: validateDOMnesting(...): <div> cannot appear as a descendant of <p> */}
                <Table.HeaderTitle>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeaderTitle>
                {header.column.getCanSort() ? <Table.SortButtons /> : null}
              </Table.HeaderCell>
            ))}
          </Table.HeaderRow>
        ))}
      </Table.Header>
      <Table.Body>
        <GenericErrorBoundary>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <Table.Row
                  isSelected={selectedPlotId === row.original.id}
                  onClick={() => {
                    setTableState({ sel: row.original.id || undefined });
                    const bbox = getBbox(row.original.geometry);
                    if (!bbox.length) return;

                    if (isEqual(defaultMapViewState, viewState)) {
                      map?.fitBounds(bbox as [LngLatLike, LngLatLike]);
                    } else {
                      map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
                        duration: 0, // disable animation when not at defaultMapViewState
                      });
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
                {row.getIsExpanded() && (
                  <Table.Row>
                    <Table.Subrow colSpan={row.getVisibleCells().length}>
                      <GenericErrorBoundary title={t<string>('error.plotInfo')}>
                        <CollapsedRow
                          isSupplierView={isSupplierView}
                          plotId={row.original.id}
                          supplierId={row.original?.suppliers?.[0].id || null}
                          supplierName={row.original?.suppliers?.[0].name || ''} // For now we only support one supplier ownership
                          riskScore={
                            row.original.mostRecentAnalysis?.riskOfDeforestation
                          }
                        />
                      </GenericErrorBoundary>
                    </Table.Subrow>
                  </Table.Row>
                )}
              </Fragment>
            );
          })}
        </GenericErrorBoundary>
      </Table.Body>
      <Table.FooterPanel table={table} limit={20} />
    </Table>
  );
};
