import {
  Stack,
  Title,
  Group,
  Text,
  UnstyledButton,
  Image,
  HoverCard,
} from '@liveeo/component-library';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useLayerState } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@mantine/hooks';
import LEGEND_ICON from '../../../assets/legend-icon.svg';
import classes from './Legend.module.css';

type Props = {
  color: string;
  isActive: boolean;
};

const LayerIndicator = styled.div<Props>`
  width: 12px;
  height: 12px;
  background-color: ${({ color, isActive }) =>
    isActive ? color : 'transparent'};
  border: ${({ color, isActive }) =>
    isActive ? `1px solid ${color}` : '1px solid #ddd'};
  cursor: pointer;
`;

export const Legend = () => {
  const { t } = useTranslation();
  const [isLegendOpen, toggleLegend] = useToggle();
  const { layerState, setLayerState } = useLayerState();

  return (
    <div className={classes.legend}>
      {isLegendOpen && (
        <Stack className={classes.content}>
          <Title order={5}>{t<string>('layerToggle.title')}</Title>
          <Group align="flex-start">
            <HoverCard position="top" openDelay={1500}>
              <HoverCard.Target>
                <UnstyledButton
                  onClick={() =>
                    setLayerState({ gFC2020: !layerState.gFC2020 })
                  }
                >
                  <Group>
                    <LayerIndicator
                      color="#65DA00"
                      isActive={!!layerState.gFC2020}
                    />
                    <Text
                      className={classes['layer-name']}
                      data-selected={layerState.gFC2020}
                    >
                      {t<string>('layerToggle.globalForestWatch.name')}
                    </Text>
                  </Group>
                </UnstyledButton>
              </HoverCard.Target>
              <HoverCard.Dropdown className={classes.tooltip}>
                <Stack>
                  <Text>
                    {t<string>('layerToggle.globalForestWatch.link.text')}
                  </Text>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                    to="https://data.jrc.ec.europa.eu/dataset/10d1b337-b7d1-4938-a048-686c8185b290"
                  >
                    <Text>
                      {t<string>('layerToggle.globalForestWatch.link.button')}
                    </Text>
                  </Link>
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>
        </Stack>
      )}
      <UnstyledButton onClick={() => toggleLegend()}>
        <Group gap={10}>
          <Image src={LEGEND_ICON} />
          <Text>
            {isLegendOpen ? `${t('legend.hide')}` : `${t('legend.show')}`}
          </Text>
        </Group>
      </UnstyledButton>
    </div>
  );
};
