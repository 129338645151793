import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import { Plot } from '../shared/types';
import { useMap, LngLatLike } from 'react-map-gl';
import { getBbox } from '../helper';
import { useTableState } from './useTableState';

export const useSupplierPlots = (supplierId: string) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();
  const queryClient = useQueryClient();
  const { deforestationMap: map } = useMap();
  const { tableState, setTableState } = useTableState();

  const { data, isLoading } = useQuery({
    queryKey: ['supplier-plots', supplierId],
    queryFn: () =>
      fetch(
        `businesses/${supplierId}/plots?includes=suppliers,mostRecentAnalysis`
      ).then((plots: Plot[] | any) => {
        const plot = plots?.length ? plots[0] : null;
        // replacing the plots cache when suppliers plots have been requested
        // in order to show only plots belonging to a selected supplier on the map
        queryClient.setQueryData(['plots'], plots);
        // update map to location of first plot in table with it selected in table
        if (!tableState.sort && !tableState.sel && plot) {
          const bbox = getBbox(plot.geometry);
          if (bbox.length) {
            map?.fitBounds(bbox as [LngLatLike, LngLatLike], { duration: 0 });
          }
          setTableState({ sel: plot.id });
        }
        return plots;
      }) as Promise<Plot[]>,
    enabled: !!supplierId,
    onError: apiError,
  });

  return { data, isLoading };
};
