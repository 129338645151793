import { useTranslation } from 'react-i18next';
import { showNotification } from '@liveeo/component-library';
import { capitalizeFirstLetter } from '../helper/text-transform';

export const useNotification = () => {
  const { t } = useTranslation();

  const apiError = () => {
    return showNotification({
      title: t<string>('error.title'),
      message: t<string>('error.data'),
      color: 'red',
    });
  };

  const successfullyCreated = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.create', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  const successfullyUpdated = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.update', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  const successfullyDeleted = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.delete', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  const successfullyInvited = (entity: string) => {
    return showNotification({
      title: t<string>('success.title'),
      message: t<string>('success.invited', {
        entity: capitalizeFirstLetter(entity),
      }),
      color: 'green',
    });
  };

  return {
    apiError,
    successfullyCreated,
    successfullyUpdated,
    successfullyDeleted,
    successfullyInvited,
  };
};
