import { Routes, Route } from 'react-router-dom';
import { useFlags } from '../../contexts/FlagsContext';
import { GenericErrorFallback } from '../../shared/components/GenericErrorFallback';
import { PlotsTablePanel } from './PlotsTablePanel';
import { SuppliersTablePanel } from './SuppliersTablePanel';
import { SupplierDetailPanel } from '../Suppliers/SupplierDetailPanel';
import { BuyersTablePanel } from './BuyersTablePanel';
import { BuyerDetailPanel } from '../Buyers/BuyerDetailPanel';
import { NotificationsPanel } from './NotificationsPanel';
import { ReceivedInvitations } from '../Notifications/ReceivedInvitations';
import { SentInvitations } from '../Notifications/SentInvitations';

export const SideNavRoutes = () => {
  const { COMMERCIAL_GRAPH } = useFlags();

  return (
    <Routes>
      <Route index element={null} />
      <Route
        path="plots/*"
        element={<PlotsTablePanel />}
        errorElement={<GenericErrorFallback />}
      />
      {COMMERCIAL_GRAPH && (
        <>
          <Route
            path="suppliers"
            element={<SuppliersTablePanel />}
            errorElement={<GenericErrorFallback />}
          />
          <Route
            path="suppliers/:supplierId"
            element={<SupplierDetailPanel />}
            errorElement={<GenericErrorFallback />}
          />
          <Route
            path="buyers"
            element={<BuyersTablePanel />}
            errorElement={<GenericErrorFallback />}
          />
          <Route
            path="buyers/:buyerId"
            element={<BuyerDetailPanel />}
            errorElement={<GenericErrorFallback />}
          />
          <Route
            path="notifications"
            element={<NotificationsPanel />}
            errorElement={<GenericErrorFallback />}
          >
            <Route index element={<ReceivedInvitations />} />
            <Route path="sent" element={<SentInvitations />} />
          </Route>
        </>
      )}
    </Routes>
  );
};
