import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

export const useToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error } = useQuery<string>({
    queryKey: ['token'],
    queryFn: () => getAccessTokenSilently(),
  });

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error, Sentry]);

  return { data, isLoading, error };
};
