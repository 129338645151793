import { Icon, Text, Group, Loader, Center } from '@liveeo/component-library';
import { Statuses, StatusColor } from '../types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

type Props = {
  text: Statuses;
};

type IconProps = {
  color: StatusColor;
};

const StatusOutline = styled.div`
  width: 80px;
  border: 1px solid #fff;
  border-radius: 2px;
  text-align: center;
  padding: 4px;
`;

const StatusIcon = ({ color }: IconProps) => (
  <Icon icon="circle" width={8} color={color} />
);

const StatusText = ({ text }: Props) => (
  <Text c="white" size="sm" fw={400} span>
    {text}
  </Text>
);

export const StatusIndicator = ({ text }: Props) => {
  const { t } = useTranslation();
  switch (text) {
    case 'high':
      return (
        <StatusOutline>
          <Group justify="center" gap="xs">
            <StatusIcon color="#D4111B" />
            <StatusText text={t('common.status.high')} />
          </Group>
        </StatusOutline>
      );
    case 'low':
      return (
        <StatusOutline>
          <Group justify="center" gap="xs">
            <StatusIcon color="#03A14F" />
            <StatusText text={t('common.status.low')} />
          </Group>
        </StatusOutline>
      );
    case 'medium':
      return (
        <StatusOutline>
          <Group justify="center" gap="xs">
            <StatusIcon color="#FFAB1A" />
            <StatusText text={t('common.status.medium')} />
          </Group>
        </StatusOutline>
      );
    case 'pending':
      return (
        <StatusOutline>
          <Center>
            <Loader size="xs" />
          </Center>
        </StatusOutline>
      );
    default:
      return (
        <StatusOutline>
          <StatusText text={text} />
        </StatusOutline>
      );
  }
};
