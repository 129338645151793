import {
  BackgroundImage,
  Center,
  Button,
  Text,
  Group,
  Stack,
  LoadingSpinner,
  Flex,
} from '@liveeo/component-library';
import { pdfjs, Document, Page } from 'react-pdf';
import { useState } from 'react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import classes from './terms-conditions.module.css';
import { useUser } from '../../hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const TermsConditions = () => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number | null>(null);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }
  const { updateUser } = useUser();
  const { logout } = useAuth0();
  const currentTerms =
    'https://uploads-ssl.webflow.com/63887771fcc0d8e316dcbd57/66b0defe0c6ca97535f69668_Platform%20Terms%20TradeAware.pdf';

  const onAccept = () => {
    updateUser({ consentedAt: new Date() });
  };
  return (
    <BackgroundImage
      src="https://live-eo-statics.netlify.app/login-background.webp"
      h="100vh"
      w="100%"
    >
      <Center p="md" h="100%">
        <Stack bg="#FCFCFC">
          <Text c="black" size="lg" p="md" fw={500}>
            {t<string>('terms.title')}
          </Text>
          <Document
            className={classes.pdfContainer}
            file={currentTerms}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={850}
                loading={
                  <Center h="100%">
                    <LoadingSpinner />
                  </Center>
                }
              />
            ))}
          </Document>
          <Flex w="95%" pl="lg">
            <Text c="black" fw={500}>
              {t<string>('terms.body')}
            </Text>
          </Flex>
          <Group justify="flex-end" mb="sm" mr="sm">
            <Button
              variant="outline"
              className={classes.cancelButton}
              onClick={() => logout()}
            >
              {t<string>('common.cancel')}
            </Button>
            <Button onClick={() => onAccept()}>
              {t<string>('terms.accept')}
            </Button>
          </Group>
        </Stack>
      </Center>
    </BackgroundImage>
  );
};
