import { useTranslation } from 'react-i18next';
import { Text } from '@liveeo/component-library';

type Props = { risk: string };

export const PlotRiskText = ({ risk }: Props) => {
  const { t } = useTranslation();
  switch (risk) {
    case 'high':
      return (
        <Text size="sm">
          <b>{`${t<string>(`plots.table.analysisResults.high.title`)}: `}</b>
          <Text span>{t<string>(`plots.table.analysisResults.high.text`)}</Text>
        </Text>
      );
    case 'low':
      return (
        <Text size="sm">
          {t<string>(`plots.table.analysisResults.low.text`)}
        </Text>
      );
    default:
      return (
        <Text size="sm">
          {t<string>(`plots.table.analysisResults.pending.text`)}
        </Text>
      );
  }
};
